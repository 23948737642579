import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SEO from '../components/App/SEO';
import { graphql } from 'gatsby';

const cookiePolicy = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO 
        postTitle='Cookie Policy | BlueMail App' 
        postDescription='Everything new about Blue Mail - Redefining Your Email'
        postImage=''
        postURL='cookie-policy'
        postSEO
    />
    <div className="container pt-120 pb-100">
        <div style={{background: '#0B71BC', color: '#e6e6e6' , padding: '40px 0', marginBottom: '40px'}}>
            <div className='row' style={{textAlign: 'center'}}>
                <div className='col-12'>
                <h1 style={{margin: '0', color: 'white'}}>Cookie Policy</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <p>
                Our Cookie Policy is specified in our <Link style={{color: '#1F6BF1'}} to='/privacy/'>Privacy Policy</Link>, which also includes additional details about the collection and use of information at Blix Inc.
                </p>
            </div>
        </div>
    </div>
    <Footer />
    </Layout>
);

export default cookiePolicy;

export const query = graphql`
query CookiePolicyPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
